var $name="findNextSibling",$path="app/utilities/findNextSibling.js",$this={$name,$path,};/**
 * Finds nextElementSibling according to predicate
 * Returns null if this is no elements matching predicate or it is the last element in the container.
 * @param { HTMLElement } element
 * @param { (element: HTMLElement) => boolean } predicate
 * @return { HTMLElement | null}
 */

function findNextSibling(element, predicate) {
  while (element) {
    // eslint-disable-next-line no-param-reassign
    element = element.nextElementSibling;
    if (predicate(element)) return element;
  }
  return null;
}

export default findNextSibling;
