var $class="se2--content-small-square-image",$name="ContentSmallSquareImage",$path="app/components/ContentSmallSquareImage/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import findNextSibling from 'app/utilities/findNextSibling';
import setTabIndex from 'app/utilities/setTabIndex';

import forEach from 'lodash/forEach';

export const descriptionClassName = 'card-text';
export const contentClassName = 'animated-content-body';
export const cardWrapperClassName = 'mosaic-card-wrapper';
export const clearClassName = 'mosaic-clear-both';

export default shell.registerComponent($this, ({
  addEventListener, elements, mount, settings,
}) => {
  const adjustDescriptionHeight = (element) => {
    const descriptionContainerElement = element.querySelector(`.${descriptionClassName}`);
    if (!descriptionContainerElement) return;
    const targetContainerElement = element.querySelector(`.${contentClassName}`);
    targetContainerElement.style.top = `${descriptionContainerElement.getBoundingClientRect().height}px`;
  };

  const insertClearBothElement = (element) => {
    const nextElementSibling = findNextSibling(
      element,
      (candidate) => !(/(script|style|svg)/i.test(candidate?.tagName)),
    );
    if (!nextElementSibling?.classList.contains(cardWrapperClassName)) {
      const clearElement = document.createElement('div');
      clearElement.classList.add(clearClassName);
      if (nextElementSibling) {
        element.parentElement.insertBefore(clearElement, nextElementSibling);
      } else {
        element.parentElement.appendChild(clearElement);
      }
    }
  };

  const handleLinkClick = (event) => {
    const { code, target } = event;

    if (isUsingKeyboard()) {
      if (code === 'Enter') {
        if (target.classList.contains('card')) {
          const link = target.querySelector('.button');
          link.click();
        }
      }
    }
  };

  const onMouseEnter = ({ target }) => {
    !settings.accessibility && !isMobileBreakpoint() && target.querySelector('video').play();
  };

  const onMouseLeave = ({ target }) => {
    target.querySelector('video').pause();
  };

  mount((element) => {
    adjustDescriptionHeight(element);
    insertClearBothElement(element);

    forEach(
      element.querySelectorAll('.button'),
      (link) => setTabIndex(link, false),
    );

    addEventListener(element, 'keydown', handleLinkClick);

    if (element.classList.contains('slide-video')) {
      addEventListener(element, 'mouseenter', onMouseEnter);
      addEventListener(element, 'mouseleave', onMouseLeave);
    }

    lazyHandleOpenModalButtons(addEventListener, element);
  });

  addEventListener(window, 'resize', () => forEach(elements, adjustDescriptionHeight));
});
